import React from 'react'

import '../styles/icomoon.css'
import '../styles/socials.sass'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome' 
import {faFacebookF, faGithub, faDiscord, faLinkedinIn, faYoutube, faTwitter} from '@fortawesome/free-brands-svg-icons'


const Socials = () => (
    <div className="socials-container">
        <SocialButton icon={faLinkedinIn} link="https://www.linkedin.com/company/polyhxcyber" colour="#0077b5"/>
        <SocialButton icon={faFacebookF} link="https://www.facebook.com/polycyber.polymtl" colour="#4267b2"/>
        <SocialButton icon={faYoutube} link="https://www.youtube.com/@polycyber/" colour="#FF0000"/>
        <SocialButton icon={faTwitter} link="https://twitter.com/polyhxcyber" colour="#1DA1F2"/>
    </div>
)

const SocialButton = ({ icon, link, colour }) => (
    <a href={link} >
        <div style={{ background: colour, color: 'white' }} className="social-button">
            <div className="icon">
                <FontAwesomeIcon icon={icon} />
            </div>
        </div>
    </a>
)

export default Socials
